
import { AppConfig, Menu } from "./interfaces/app-config";


export var globalConfig: AppConfig = {
    menu: [
        Menu.create({
            id: 2,
            path: '/home',
            title: 'Comprar',
        }),
        Menu.create({
            id: 3,
            path: '/tickets-saved',
            title: 'Mis Tickets',
        }),
    ],
    mobileMenu: [
        Menu.create({
            id: 1,
            path: '/home',
            title: 'Comprar',
            icon: '/assets/icons/home_white.svg',
            iconType: 'img'
        }),
        Menu.create({
            id: 3,
            path: '/tickets-saved',
            title: 'Mis Compras',
            icon: '/assets/icons/ticket_white.svg',
            iconType: 'img'
        }),
        // Menu.create({
        //     id: 3,
        //     path: '/search',
        //     title: 'Buscar',
        //     icon: '/assets/icons/search_white.svg',
        //     iconType: 'img'
        // }),
    ],
    footerMenu: [
        {
            id: 1,
            title: 'Páginas',
            menu: [
                Menu.create({
                    id: 1,
                    path: '/search',
                    title: 'Términos de búsqueda',
                    external: false,
                }),
                Menu.create({
                    id: 2,
                    path: '/privacy-policy',
                    title: 'Política de privacidad y cookies',
                    external: false,
                }),
                Menu.create({
                    id: 3,
                    path: 'https://staging.todotix.com/sales/guest/form/',
                    title: 'Pedidos y devoluciones',
                    external: true,
                    target: "_blank"
                }),
                Menu.create({
                    id: 4,
                    path: 'https://staging.todotix.com/contact/',
                    title: 'Contáctanos',
                    external: true,
                    target: "_blank"
                }),
                Menu.create({
                    id: 4,
                    path: '/search',
                    title: 'Búsqueda avanzada',
                    external: false,
                }),
                Menu.create({
                    id: 4,
                    path: '/affiliate-program',
                    title: 'Programa de afiliación',
                    external: false,
                }),
            ]
        },
        {
            id: 2,
            title: 'Contacto',
            menu: [
                Menu.create({
                    id: 8,
                    path: 'tel:59169777799',
                    title: '59169777799',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/call.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 8,
                    path: 'mailto:soporte@todotix.com',
                    title: 'soporte@todotix.com',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/mail.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 8,
                    path: 'https://www.facebook.com/libelula.bo',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/facebook.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 9,
                    path: 'https://www.instagram.com/libelula_bo',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/instagram.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 10,
                    path: 'https://api.whatsapp.com/send/?phone=59169777799&text=Tengo+una+consulta+respecto+a+Todotix&type=phone_number&app_absent=0',
                    title: 'Todotix',
                    external: true,
                    target: '_blank',
                    icon: '/assets/icons/whatsapp.svg',
                    iconType: 'img'
                }),
            ]
        }
    ],
    pages: {
        login: {
            title: 'Inicia sesión en tu cuenta',
            subtitle: '¡Bienvenido de vuelta! Por favor, ingresa tus datos.',
            form: {
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                rememberMe: {
                    label: 'Recordarme'
                },
            },
            forgotPassword: '¿Olvidaste tu contraseña?',
            submit: 'Iniciar sesión',
            nextAuth: {
                question: '¿No tienes una cuenta?',
                action: 'Registrate'
            }
        },
        register: {
            title: 'Crea tu cuenta',
            subtitle: 'Por favor, ingresa los datos requeridos',
            form: {
                name: {
                    label: 'Nombre completo',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                phone: {
                    label: 'Teléfono celular',
                    placeholder: 'Ingresa tu celular'
                },
                city: {
                    label: 'Ciudad',
                    placeholder: 'Selecciona tu ciudad'
                },
                credential: {
                    label: 'Número de identificación',
                    placeholder: 'Ingresa tu identificación'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                repeatPassword: {
                    label: 'Repite la contraseña',
                    placeholder: 'Repide tu contraseña'
                },
            },
            submit: 'Registrarse',
            nextAuth: {
                question: '¿Ya tienes una cuenta?',
                action: 'Inicia sesión'
            }
        },
    },
    validations: {
        required: "El campo \"{label}\" es requerido.",
        number: "El campo \"{label}\" debe contener solo números.",
        minlength: "El campo \"{label}\" debe tener al menos \"{requiredLength}\" caracteres.",
        maxlength: "El campo \"{label}\" debe tener un máximo de \"{requiredLength}\" caracteres.",
        min: "El campo \"{label}\" debe contener al menos \"{min}\" elementos.",
        max: "El campo \"{label}\" debe tener un máximo de \"{max}\" elementos.",
        email: "El campo \"{label}\" debe ser un email válido.",
        default: "El campo \"{label}\" es inválido.",
    },
};
