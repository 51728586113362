import { Injectable } from '@angular/core';
import { EncryptionService } from './encryption.service';
import { AppConfig } from '../interfaces/app-config';
import { globalConfig } from '../global-config';
import { getEnvironment } from '../environment/environment';
import { ENV } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProjectConfigService {
  env:ENV = getEnvironment();
  private config!: AppConfig;

  constructor(
    private encryptionService: EncryptionService,
  ) {}

  private deepMerge(target: any, source: any): any {
    if (!target || typeof target !== 'object') {
      target = {};
    }

    for (const key of Object.keys(source)) {
      if (Array.isArray(source[key])) {
        // Reemplaza completamente los arrays
        target[key] = source[key];
      } else if (source[key] instanceof Object) {
        // Fusiona objetos recursivamente
        target[key] = this.deepMerge(target[key], source[key]);
      } else {
        // Asigna valores primitivos
        target[key] = source[key];
      }
    }

    return target;
  }

  loadConfig(): Promise<any> {
    const globalConfig$ = Promise.resolve(globalConfig);
    // console.log(window.selfPc);
    let _x = '12345678901234567890123456789012', _y = '1234567890123456';
    let selfPc = this.encryptionService.decrypt(window.selfPc, _x, _y);
    // console.log(selfPc);
    const projectConfig$ = Promise.resolve(selfPc);


    return Promise.all([globalConfig$, projectConfig$]).then(([globalConfig, projectConfig]) => {
        // console.log(this.deepMerge(globalConfig, projectConfig || {}));
      this.config = this.deepMerge(globalConfig, projectConfig || {});
    });
  }

  getConfig(): AppConfig {
    if(this.env.type === 'madness'){
      this.config.menu = [
        {
          id: 2,
          path: '/home',
          title: 'Comprar',
        },
        {
          id: 3,
          path: '/tickets-saved',
          title: 'Mis Compras',
        },
      ]
    }
    return this.config;
  }
}
